export const static_translation = {
    "landpage_banner_balloon": "एक नई भाषा में सुधार करें!",
    "landpage_flags_title": "Mobdict® के साथ एक भाषा में सुधार करें",
    
    "landpage_how_works_title": "हमारी सबसे बड़ी खुशी आपको एक नई भाषा सीखने में मदद करना है ❤️",
    "landpage_how_works_text": "हमें भाषाओं और शिक्षा के प्रति जुनून है। हमारा लक्ष्य सभी के लिए सीखने को सुलभ और मजेदार बनाना है, एक नवोन्मेषी दृष्टिकोण का उपयोग करके जो डिक्टेशन पर आधारित है।",
    "landpage_how_works_action": "मैं अधिक जानना चाहता हूँ, चलिए शुरू करते हैं",
    
    "landpage_learn_title": "और भी बहुत कुछ सीखें 😎",
    "landpage_learn_text": "हमारा शिक्षण तरीका भाषा को डिक्टेशनों के माध्यम से अभ्यास करने पर केंद्रित है, एक तकनीक जो सुनने की समझ, पढ़ने और लेखन अभ्यास को मिलाकर आपकी भाषा क्षमताओं को स्वाभाविक और प्रभावी तरीके से सुधारता है।",
    "landpage_learn_action": "अब शुरू करें",
    
    "landpage_write_title": "हैंड राइटिंग करें और Mobdict® के साथ अधिक सीखें",
    "landpage_write_text": "हैंड राइटिंग शब्दावली की याददाश्त और समझ को बेहतर बना सकती है, क्योंकि इसमें गहरे संज्ञानात्मक प्रक्रियाएं शामिल होती हैं। अपने हस्तलिखित दस्तावेज़ Mobdict® को Mobdict® Image Sender ऐप के माध्यम से भेजें।",
    
    "landpage_free_title": "मुफ्त ट्रायल 🥹",
    "landpage_free_text": "आप अपने वर्तमान स्तर पर भाषा का अभ्यास कर सकते हैं, Mobdict® को एक सप्ताह के लिए मुफ्त में परीक्षण करके और 7 डिक्टेशन तक कर सकते हैं, ताकि देख सकें कि आपकी शब्दावली हर चुनौती के साथ कैसे बढ़ती है।",
    "landpage_free_action": "मैं प्रयास करना चाहता हूँ",
    
    "landpage_more_title": "सर्वश्रेष्ठ तरीके से सीखें 🎬",
    "landpage_more_text": "हमारा वीडियो देखें ताकि आप समझ सकें कि कैसे डिक्टेशनों के माध्यम से भाषा सीखना आपके लिए फायदेमंद हो सकता है।",
    
    "faq_title": "FAQ - अक्सर पूछे जाने वाले प्रश्न",
    "faq_question_1": "कोर्स में कौन भाग ले सकता है?",
    "faq_answer_1": "कोर्स में कौन भाग ले सकता है, यह जानने के लिए आपको संस्थान या कोर्स के जिम्मेदार लोगों द्वारा निर्धारित विशेष आवश्यकताओं की जांच करनी होगी।",
    "faq_question_2": "हमारा कोर्स शुरुआती और उन लोगों के लिए आदर्श है जो अपनी भाषा की क्षमताओं को सुधारना चाहते हैं।",
    "faq_answer_2": "हमारा कोर्स शुरुआती और उन लोगों के लिए आदर्श है जो अपनी भाषा की क्षमताओं को और विकसित करना चाहते हैं। इसलिए, कोई भी जो शुरू कर रहा है या अपनी भाषा की क्षमताओं को और सुधारना चाहता है, भाग ले सकता है।",
    "faq_question_3": "डिक्टेशन के माध्यम से सीखने की विधि कैसे काम करती है?",
    "faq_answer_3": "डिक्टेशन के माध्यम से सीखने की विधि एक तकनीक है जो सुनने की क्षमताओं, वर्तनी और पाठ की समझ को सुधारने में मदद करती है।",
    "faq_question_4": "कोर्स की अवधि क्या है?",
    "faq_answer_4": "कोर्स की अवधि कई कारकों पर निर्भर कर सकती है, जैसे कि जो संस्थान इसे पेश करता है, कुल घंटों की संख्या और कक्षाओं की आवृत्ति।",
    
    "footer_talk_us": "हमसे संपर्क करें",
    "footer_support": "सहायता",
    "footer_all_rights": "सर्वाधिकार सुरक्षित",
    
    "lg-google-auth": "गूगल ऑथेंटिकेटर कोड दर्ज करें",

    "dashboard_home_see_plans": "योजना देखें",
    "dashboard_home_dicts_done": "पूरा किया",
    "dashboard_home_dicts_plan": "खरीदी गई डिक्टेशन",
    "dashboard_home_dicts_test": "परीक्षण अवधि",
    "dashboard_home_dicts_days": "शेष दिन",
    "dashboard_home_dicts_test_done": "परीक्षण अवधि समाप्त",
    "dashboard_home_dicts_dict": "डिक्टेशन",
    
    "dashboard_home_learning": "मैं सीख रहा हूँ",
    "dashboard_home_level": "स्तर",
    "dashboard_home_cta": "डिक्टेशन शुरू करें",
    
    "dashboard_plan_title": "हमारी योजनाओं में से एक के लिए सब्सक्राइब करें",
    "dashboard_plan_subtitle": "वार्षिक बिलिंग",
    "dashboard_plan_pop": "सबसे लोकप्रिय",
    "dashboard_plan_1": "बेसिक प्लान",
    "dashboard_plan_2": "इंटरमीडिएट प्लान",
    "dashboard_plan_3": "एडवांस्ड प्लान",
    "dashboard_plan_dict": "कहावतें",
    "dashboard_plan_action": "सबसक्राइब करें",
    "dashboard_plan_currency": "₹",
    
    "dashboard_talkus_title": "हमसे संपर्क करें",
    "dashboard_talkus_email": "ईमेल",
    "dashboard_talkus_support_email": "तकनीकी सहायता",
    "dashboard_talkus_support_address": "पता",
    "dashboard_talkus_support_social": "सोशल मीडिया",
    "dashboard_talkus_about_title": "हमारे बारे में",
    "dashboard_talkus_about_text": "Mobdict® पर, आपकी भाषा सीखने की प्रक्रिया को कहावतों के साथ आसान और मजेदार बनाना। ये छोटी-छोटी ज्ञान की बातें संस्कृति और भाषा को अवशोषित करने का एक अनूठा तरीका प्रदान करती हैं। जब आप एक नई भाषा सीखते हैं, तो उन कहावतों को खोजें जो मूल भाषा बोलने वालों के सोचने और जीने के तरीके को दर्शाती हैं। प्रत्येक अभिव्यक्ति मूल्यवान पाठ और सांस्कृतिक संदर्भ लाती है, जिससे आपको सूक्ष्मताओं को समझने और अधिक स्वाभाविक रूप से बोलने में मदद मिलती है।",

    "dashboard_home_ranking_title": "रैंकिंग",
    "dashboard_home_ranking_position": "पद.",
    "dashboard_home_ranking_name": "नाम",
    "dashboard_home_ranking_points": "अंक",
    "dashboard_home_ranking_see_more": "और देखें",

    "dashboard_side_home": "होम",
    "dashboard_side_ranking": "रैंकिंग",
    "dashboard_side_buy": "क्रेडिट खरीदें",
    "dashboard_side_account": "मेरा खाता",
    "dashboard_side_settings": "सेटिंग्स",
    "dashboard_side_faq": "FAQ",
    "dashboard_side_talk_us": "हमसे संपर्क करें",
    "dashboard_side_exit": "लॉगआउट",


    "dashboard_form_password_validation": "नया पासवर्ड और पुष्टि मेल नहीं खाते",

    "dashboard_form_name": "पूरा नाम",
    "dashboard_form_nickname": "अपना यूजरनेम (उपनाम) चुनें",
    "dashboard_form_email": "ईमेल",
    "dashboard_form_phone": "मोबाइल",
    "dashboard_form_password": "पासवर्ड",
    "dashboard_form_level": "स्तर",
    "dashboard_form_confirm_password": "पासवर्ड दोहराएँ",

    "dashboard_form_account_title": "मेरा खाता",
    "dashboard_form_password_title": "पासवर्ड बदलें",
    
    "dashboard_form_cancel": "रद्द करें",
    "dashboard_form_next": "जारी रखें",
    "dashboard_form_save": "सहेजें",
    "dashboard_form_subgender": "उप-श्रेणियाँ चुनें",
    
    "dashboard_settings_title": "सेटिंग्स",
    "dashboard_settings_2fa": "दो-कारक प्रमाणीकरण सक्षम करें",
    "dashboard_settings_adjust_pause": "डिक्टेशन में विराम समायोजित करें",
    "dashboard_settings_pause": "विराम",
    "dashboard_settings_subgender": "पसंदीदा उप-श्रेणियाँ रीसेट करें (न्यूनतम 3):",
    "dashboard_settings_define": "परिभाषित करें",
    "dashboard_settings_default_lang": "डिफ़ॉल्ट भाषा",
    "dashboard_settings_native_lang": "मूल भाषा",
    "dashboard_settings_learn_lang": "सीखने की भाषा",
    "dashboard_settings_recurrency": "पुनरावर्ती सदस्यता",
    
    
    "dashboard_dict_congrats": "बधाई हो!",
    "dashboard_dict_added_increase": "आपने अपने शब्दकोश में अधिक जोड़ा",
    "dashboard_dict_added_words": "शब्द।",
    "dashboard_dict_restart": "नई डिक्टेशन शुरू करें",
    "dashboard_dict_back_homepage": "होमपेज पर वापस जाएं",
    

    "dashboard_dict_wrong_words": "शब्द गलत हैं",
    "dashboard_dict_wrong_omit": "छुटे हुए शब्द",
    "dashboard_dict_wrong_finish": "समाप्त करें",
    "dashboard_dict_wrong_challange": "चुनौती",
    "dashboard_dict_wrong_reinforcement": "पुनरावृत्ति डिक्टेशन करें",
    
    "dashboard_dict_translate": "अनुवाद",
    "dashboard_dict_see_translate": "अनुवाद देखें",
    
    "dashboard_dict_writing_title": "टेक्स्ट टाइप करें",
    "dashboard_dict_writing_handed": "हाथ से लिखें",
    "dashboard_dict_writing_special": "विशेष अक्षर",
    
    "dashboard_dict_writing_step_1": "1. Mobdict Image Sender ऐप को App Store या Play Store से डाउनलोड करें और लॉगिन करें।",
    "dashboard_dict_writing_step_2": "2. आपने जो डिक्टेशन सुना है उसे एक कागज पर लिखें और एक फोटो लें।",
    "dashboard_dict_writing_step_3": "3. ऐप के माध्यम से छवि भेजें और समाप्त करने के लिए बटन पर क्लिक करें।",
    "dashboard_dict_writing_step_action": "मैंने भेज दिया है",
    "dashboard_dict_writing_step_confirm": "मैंने चेक कर लिया है, सुधारें",
    
    "dashboard_dict_writing_progress": "आपकी प्रगति",
    "dashboard_dict_writing_meta": "लक्ष्य",
    "dashboard_dict_writing_words": "शब्द",
    
    "dashboard_register_title": "अपने विवरण दर्ज करें",
    "dashboard_register_subtitle": "अपने पंजीकरण विवरण प्रदान करें",
    
    "dashboard_password_title": "अपना पासवर्ड बनाएं",
    "dashboard_register_level": "भाषा और स्तर चुनें",
    "dashboard_register_subgender": "उप-श्रेणी चुनें",
    
    "dashboard_register_terms_1": "चयन करने और जारी रखने पर, आप हमारी",
    "dashboard_register_terms_2": "सेवा शर्तों",
    "dashboard_register_and": "और",
    "dashboard_register_terms_3": "गोपनीयता नीति",
    "dashboard_register_terms_3": "सेवा शर्तों",
    
    "dashboard_register_validation_not_match": "नया पासवर्ड और पुष्टि मेल नहीं खाते",
    "dashboard_register_validation_accept_terms": "शर्तें स्वीकार करें",
    "dashboard_register_validation_email": "ईमेल प्रदान करें",
    "dashboard_register_validation_password": "नया पासवर्ड प्रदान करें",
    "dashboard_register_validation_password_confirmation": "नया पासवर्ड पुष्टि प्रदान करें",
    "dashboard_register_successfull": "खाता सफलतापूर्वक बनाया गया",
    
    "dashboard_register_step1_title": "अपने विवरण दर्ज करें",
    "dashboard_register_step1_text": "अपने पंजीकरण विवरण प्रदान करें",
    "dashboard_register_step1_action": "जारी रखें",
    
    "dashboard_register_step2_title": "पंजीकरण सफलतापूर्वक पूरा हुआ",
    "dashboard_register_step2_text": "पंजीकरण के लिए धन्यवाद।",
    "dashboard_register_step2_action": "बंद करें",
    "dashboard_register_step2_back": "वापस जाएं",

    "dashboard_forgot_step1_title": "क्या आपने अपना पासवर्ड भूल गए?",
    "dashboard_forgot_step1_text": "अपना पंजीकृत ईमेल दर्ज करें ताकि आप अपना पासवर्ड पुनर्प्राप्त कर सकें",
    "dashboard_forgot_step2_title": "अपने ईमेल की जांच करें",
    "dashboard_forgot_step2_text": "अपने पंजीकृत ईमेल पर भेजे गए पुनर्प्राप्ति लिंक पर जाएं",
    
    "dashboard_createpassword_step1_title": "नया पासवर्ड",
    "dashboard_createpassword_step1_text": "अपने खाते के लिए एक नया पासवर्ड दर्ज करें",
    "dashboard_createpassword_step2_title": "नया पासवर्ड सफलतापूर्वक बनाया गया",
    "dashboard_createpassword_step2_text": "अब आप अपने नए पासवर्ड के साथ लॉगिन कर सकते हैं",


    "login_mfa": "Google प्रमाणक ऐप, या किसी अन्य प्रमाणीकरण ऐप का उपयोग करके, अपना टोकन उत्पन्न करने के लिए नीचे दिए गए QRCode को स्कैन करें",


    "register_validation_subgender": "जारी रखने के लिए कम से कम 3 उप-शैलियों का चयन करें",
    "dict_word_bellow": "पाठ को पूरा करने के लिए नीचे दिए गए शब्दों का उपयोग करें",
    "dict_reinforcement": "सुदृढीकरण लिखाई",
    "dict_a_newdict": "एक नया लिखाई प्रारंभ करें",
    "dict_by": "द्वारा लिखित",
    "dict_finish": "समाप्त करें",
    "dict_next": "जारी रखें",
    "dict_correct": "सुधारें",
    
    "Autoajuda": "स्वयं सहायता",
    "Biografia": "जीवनी",
    "Comédia": "कॉमेडी",
    "Conto": "कहानी",
    "Crônica": "क्रोनिकल",
    "Ecologia": "पारिस्थितिकी",
    "Epopeia": "महाकाव्य",
    "Fábula": "कथा",
    "Farsa": "फार्स",
    "Ficção": "उपन्यास",
    "Infantojuvenil": "बाल और युवा साहित्य",
    "Novela": "नवेला",
    "Poesia": "कविता",
    "Romance": "उपन्यास",
    "Suspense": "सस्पेंस",
    "Tragédia": "ट्रैजेडी",
    "True Crime": "सच्चा अपराध"

}
