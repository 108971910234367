import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Content, MuteButton, PlayButton, StyledVideo, VideoWrapper } from './styled';




const VideoPlayer = ({ url }) => {

  const videoRef = useRef(null);


  return (
    <VideoWrapper>
      <StyledVideo
        ref={videoRef}
        src={url}
        controls
        onEnded={() => videoRef.current.play()}
      />
    </VideoWrapper >
  );
};

export default VideoPlayer;