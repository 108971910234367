import ContainerAuthenticated from 'containers/Authenticated'
import { CoreContext } from 'context/CoreContext';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { SayingContainer, SayingContent } from './styled';
import SuccessAndError from 'components/SuccesAndError';
import SayingBody from 'components/SayingBody';
import { theme } from 'ui/theme-color';
import { CreateDictByUserId, GetDictAudio, GetDictAudioOriginal, SentDictByUserId, ValidateImageByUserId } from 'services/mobdict';
import { ReadObject } from 'services/storage';
import { toast } from 'react-toastify';
import { exposeMobdictError } from 'utils';
import useI18n from 'hooks/useI18n';
const data = {
  text: "Um programador estava discutindo com um colega sobre a eficiência de suas funções. Para ilustrar seu ponto, ele disse: \"Eu sempre comparo o código a uma receita de bolo. Se o bolo não crescer, o problema pode estar na receita, ou então na maneira como você a está interpretando.\" O colega, um pouco confuso, respondeu: \"Então, você quer dizer que devemos sempre verificar se a receita está correta antes de adicionar os ovos?\" O programador sorriu e disse: \"Não, quero dizer que a culpa não está apenas no forno – talvez o problema esteja em como você está batendo a massa!\"",
  words: ["programador", "ilustrar", "receita", "forno"],
  backgroundColor: theme.palette.wrong.main,
};
export default function Saying() {
  const { setOpened, noHeader, toCorrect, setToCorrect, setNoHeader, setReinforcement, user, dict, setDict, setChallenge, setForm, setImgModal, setCorrect } = useContext(CoreContext)
  const [error, setError] = useState(false)
 
  const [loading, setLoading] = useState(false)

  const {t} = useI18n(false)
  
  const [audioOriginal, setAudioOriginal] = useState(null)
  const [audioDict, setAudioDict] = useState(null)
  const [misQr, setMisQr] = useState(null)

  useEffect(() => {
    setOpened(false)
    setNoHeader(false)
  }, [])

  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const handleReinforcement = () => {
    navigate('saying-reinforcement');
    setReinforcement(true)
  }

  const sayingItems = {
    label: 'Corrigir',
    writingAction: (text) => save(text),
  }

  const valid = (text) => {
    if ( (!text || !text.length)  ) {
        toast.error( t("lg-error-pc") ); 
        return false;
    } 
    return true
  }

  const save = async (text) => {
    if (!valid(text) ) { return; }
    if(!loading){
      setLoading(true)

        const result = await SentDictByUserId({
          texto: text,
          id_ditado: dict?.id_ditado,
          update_qtd: true,
          texto_reforco: "",
          tipo: 0
        }, user?.id)

        // console.log("result", result)

        if( !exposeMobdictError(result, !( typeof result?.data === 'string') )){
          setError(!!result?.data?.texto_reforco?.length)
          setToCorrect(result?.data)
        }

      setLoading(false)
    }
  }
  
  const init = async () => {
    if(!loading && dict?.audio_original){
      setLoading(true)
        setForm({})
        setReinforcement(null)
        const original = await GetDictAudioOriginal(dict?.audio_original)
        const result = await GetDictAudio(dict?.audio_ditado)

      // console.log("Dict SOUND", original, result)

      setAudioDict(result)
      setAudioOriginal(original)

      // if( !exposeMobdictError(result, !!(result?.data?.texto_ditado) )){
      //   toast.success(result?.message)
      //   setCurrentDict(result)
      // }
        
      setLoading(false)
    }
  }

  const restart = async () => {

    if(!loading){

      setLoading(true)
        const auth = await ReadObject('authentication')
        const result = await CreateDictByUserId({
          tipo: auth?.tipo,
          break_time: user?.pausa || 9
        }, user?.id)
  
      console.log("Dict", result)
  
      if( !exposeMobdictError(result, !!(result?.data?.voz) ) ){
        setError(false)
        setToCorrect(null)
        setChallenge(null)
        setReinforcement(null)
        setForm({})
        setDict(result?.data) 
      }
        
      setLoading(false)
    }
  }

  const confirmImage = async () => {
    setLoading(true)
    const result = await ValidateImageByUserId(user?.id)
    setLoading(false)

    // console.log("confirmed image", result)

    if( !exposeMobdictError(result, !!result?.data?.texto_aluno) ){
      setImgModal(result?.data)
      setCorrect(result?.data)
    }

  }

  useEffect(() => { init() ;}, [dict])


  return (
    <>
      <ContainerAuthenticated close saying noHeader={noHeader}>
        {
          <SayingContainer>
            <SayingContent center={!error}>
              {
                toCorrect ?
                  <SuccessAndError restart={restart} loading={loading} toCorrect={toCorrect} error={error} action={handleReinforcement} />
                  :
                  <>
                    <SayingBody {...sayingItems} audioOriginal={audioOriginal} audioDict={audioDict} loading={loading} confirmImage={confirmImage} />
                  </>
              }
            </SayingContent>
          </SayingContainer>
        }
      </ContainerAuthenticated>
    </>
  )
}
