import React, { useState, createContext, useEffect } from 'react'
import { ReadObject, SaveObject } from '../services/storage'
import { optionsLanguage } from 'utils/options'
import { ReadUser } from 'services/mobdict'
import { exposeMobdictError } from 'utils'

export const CoreContext = createContext({})

export const CoreState = ({ children }) => {

	const [user, setUser] = useState(ReadObject('user') ? ReadObject('user') : null)

	const [language, setLanguage] = useState(ReadObject('language') ? ReadObject('language') : 'Portuguese')
	const [translation, setTranslation] = useState(ReadObject('translation') ? ReadObject('translation') : null)
	const [lastKeep, setLastKeep] = useState(ReadObject('lastKeep') ? ReadObject('lastKeep') : null)

	const [dict, setDict] = useState(ReadObject('dict') ? ReadObject('dict') : null)
	const [plan, setPlan] = useState(ReadObject('plan') ? ReadObject('plan') : null)
	
	const [plans, setPlans] = useState(ReadObject('plans') ? ReadObject('plans') : [])
	const [words, setWords] = useState(ReadObject('words') ? ReadObject('words') : [])
  const [ranking, setRanking] = useState(ReadObject('ranking') ? ReadObject('ranking') : [])
  const [testDrive, setTestDrive] = useState(ReadObject('testDrive') ? ReadObject('testDrive') : {})

	const [opened, setOpened] = useState(false)
	const [modal, setModal] = useState(null)
	const [noHeader, setNoHeader] = useState(false)
	
  const [checkout, setCheckout] = useState(null)

	const [reinforcement, setReinforcement] = useState(false)
	const [challenge, setChallenge] = useState(false)
	
	const [toCorrect, setToCorrect] = useState(false)
	const [toCorrectReinforcement, setToCorrectReinforcement] = useState(false)

	const [form, setForm] = useState({})

	const [imgModal, setImgModal] = useState(false);
	const [correct, setCorrect] = useState(false);

    const fetchUser = async (id) => {
        const result = await ReadUser(id)
        if(!exposeMobdictError(result, (!!result?.data?.email) )){
            // console.log("USER", result?.data)
            setUser({ ...result?.data, id})
            return result?.data;
        }
        return null;
    }

	const handleStateReset = () => {
		setOpened(false)
		setNoHeader(false)
		setReinforcement(false)
		setChallenge(false)
		setToCorrect(false)
	}

	const contextValue = {
		user, setUser,
		opened, setOpened,
		modal, setModal,
		noHeader, setNoHeader,
		reinforcement, setReinforcement,

		handleStateReset,

		challenge, setChallenge,
		toCorrect, setToCorrect,

		language, setLanguage,
		translation, setTranslation,

		dict, setDict,

    checkout, setCheckout,

		plan, setPlan,
    testDrive, setTestDrive,
		plans, setPlans,
		words, setWords,
		ranking, setRanking,

		lastKeep, setLastKeep,

		fetchUser,

		toCorrectReinforcement, setToCorrectReinforcement,

		form, setForm,

		imgModal, setImgModal,
		correct, setCorrect,

	}

	// to persist state when app reload  
	useEffect(() => { SaveObject('user', user); }, [user])
	useEffect(() => { SaveObject('dict', dict); }, [dict])
	useEffect(() => { SaveObject('plan', plan); }, [plan])
  useEffect(() => { SaveObject('testDrive', testDrive); }, [testDrive])
	useEffect(() => { SaveObject('plans', plans); }, [plans])
	useEffect(() => { SaveObject('words', words); }, [words])
	useEffect(() => { SaveObject('ranking', ranking); }, [ranking])
	useEffect(() => { SaveObject('language', language); }, [language])
	useEffect(() => { SaveObject('translation', translation); }, [translation])

	return <CoreContext.Provider value={contextValue}>{children}</CoreContext.Provider>
}
