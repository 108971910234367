import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    FormTitle,
    FormText,
    FormSpacer,
    RegisterCall,
    RegisterContainer,
    RegisterContent,
    RegisterFormContent,
    CheckContainer,
    Text,
    Link,
    SuccessContent,
    PasswordContent
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input';

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoRegister } from "services/authentication";
import { exposeMobdictError, exposeStrapiError } from "utils";
import { ButtonContainer, Icon, Title } from "ui/styled";
import PhoneNumberInput from "components/Phone";
import Select from "components/Form/Select";
import { languages, nivels, optionize, optionizeArray, optionsLevel } from "utils/options";
import Check from "components/Form/Check";
import { Container } from "reactstrap";
import CheckItems from "components/CheckItems";
import { ReadInfos, RegisterUser, CheckEmail } from "services/mobdict";
import useI18n from "hooks/useI18n";
import { CoreContext } from "context/CoreContext";
import { useContext } from "react";
import PasswordValidation from "components/Form/PasswordValidation";


export default function Register() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(0)
    const [checked, setChecked] = useState([])
    const [termChecked, setTermChecked] = useState(false)
    const [validPassword, setValidPassword] = useState(false)

    const [options, setOptions] = useState(null)

    const { language, checkout } = useContext(CoreContext)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const {t} = useI18n()

    const valid = (verbose = false) => {

        if (
            (!formValue('name') || !formValue('name').length) ||
            (!formValue('username') || !formValue('username').length) ||
            
            (!formValue('email') || !formValue('email').length) ||
            (!formValue('password') || !formValue('password').length) ||
            (!formValue('phone') || !formValue('phone').length) ||

            (!formValue('learning')) ||
            (!formValue('native')) ||
            (!formValue('level')) ||
            
            (!checked.length)
        ) {
            if (verbose) { toast.error( t("lg-error-pc") ); }
            return false;
        }

        if (formValue('password') !== formValue('cpassword')) {
            if (verbose) { toast.error( t("dashboard_register_validation_not_match") ); }
            return false;
        }

        if( (!termChecked) ) {
            if (verbose) { toast.error( t("dashboard_register_validation_accept_terms") ); }
            return false;
        }

        return true
    }

    const validate_email = async (email, language) => {
      let regex = new RegExp("[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}")
      let valid_format = regex.test(email)
      if (!valid_format) {
          toast.error( "Formato de email inválido" )
          return false
      }
  
      let result = await CheckEmail({ "email": email, "idioma": language })
      console.log(!result?.message === "Válido")
      if( !exposeMobdictError(result, (result?.message === "Válido") )){
          return true
      } else {
          toast.error(result?.message)
          return false
      }
  }

    const action = async () => {
        if (!valid(true)) { return; }
        setLoading(true)

        const result = await DoRegister({
            ...form,
            username: form.email?.replace(/ /g, ''),
            email: form.email?.replace(/ /g, ''),
            confirmed: true,
            blocked: false,
            role: 1
        })

        setLoading(false)
        if (result && !exposeStrapiError(result)) {
            completeLogin()
        }
    }

    const completeLogin = () => {
        toast.success( t("dashboard_register_successfull") );
        navigate('login')
    }

    const steps = useMemo(() => [
        {
            title: t("dashboard_register_step1_title"),
            text: t("dashboard_register_step1_text"),
            label: t("dashboard_register_step1_action"),
        },
        {
            title: t("dashboard_register_step2_title"),
            text: t("dashboard_register_step2_text"),
            label: t("dashboard_register_step2_action"),
        },
    ], []);

    const next = () => {
        if (step === steps.length - 2) {
            save()
            return;
        }
        if (step === steps.length - 1) {
            navigate('Login')
            return;
        }
        setStep(step + 1);
    }

    const back = () => {
        if (step === 0) {
            navigate('Login')
            return;
        }
        setStep(step - 1);
    }

    const save = async () => {

        if (!validPassword) {
            toast.error(t("Senha inválida"))
            return ;
        }

        if( !(checked?.includes('Infantojuvenil') || checked?.length >= 3 ) ){
            toast.error(t("register_validation_subgender"))
            return ;
        }

        if(!await validate_email(form?.email, language)) {
            return ;
        }
      

        if (!valid(true)) { return; }
        if(!loading){
            setLoading(true)
            let payload = {}
            if (checkout){
              payload = {
                "nome": form?.name,
                "username": form?.username,
                "email": form?.email,
                "escola": null,
                "telefone": form?.phone,
                "telefone_pais": "Brazil (Brasil)",
                "idioma_default": form?.learning,
                "idioma_nativo": form?.native,
                "nivel": form?.level,
                "subgeneros": checked,
                "senha": form?.password,
                "url": window.location.origin + '/Login',
                "checkout": true
              }
            } else {
              payload = {
                  "nome": form?.name,
                  "username": form?.username,
                  "email": form?.email,
                  "escola": null,
                  "telefone": form?.phone,
                  "telefone_pais": "Brazil (Brasil)",
                  "idioma_default": form?.learning,
                  "idioma_nativo": form?.native,
                  "nivel": form?.level,
                  "subgeneros": checked,
                  "senha": form?.password,
                  "url": window.location.origin + '/Login',
              }
            }
    
            const result = await RegisterUser(payload)
    
            if( !exposeMobdictError(result, (typeof result?.data !== 'string') )){
                toast.success(result?.message)
                setStep(step + 1)
            }
            setLoading(false)
        }
    }

    const init = async () => {
        const result = await ReadInfos(language)
        if( !exposeMobdictError(result, (!!result?.data?.idiomas_aprendizado) )){
            setOptions({
                native: optionize(result?.data?.idiomas_nativos) || [],
                learning: optionize(result?.data?.idiomas_aprendizado) || [],
                subgenders: optionizeArray(result?.data?.subgeneros) || [],
                levels: [ ...optionsLevel ]
            })
        }
    }

    useEffect(() => { init() ;}, [])

    return (
        <>
            <ContainerUnauthenticated>
                <RegisterContainer>
                    {!(step === 0) ? null :
                        <>
                            <RegisterContent>
                                <Title hasIcon>
                                    <Icon icon='logo-mobdict' nomargin width={44} />
                                    {steps?.[step]?.title}</Title>
                                <FormText>{steps?.[step]?.text}</FormText>
                                <RegisterFormContent>
                                    <Input placeholder={ t("dashboard_form_name") } id={'name'} value={formValue('name')} onChange={e => changeForm(e.target.value, 'name')} />
                                    <Input placeholder={ t("dashboard_form_nickname") } id={'username'} value={formValue('username')} onChange={e => changeForm(e.target.value, 'username')} />
                                </RegisterFormContent>
                                <FormSpacer />
                                <RegisterFormContent>
                                    <Input placeholder={t("dashboard_form_email")} id={'email'} value={formValue('email')} onChange={e => changeForm(e.target.value, 'email')} />
                                    <PhoneNumberInput  value={formValue('phone')} onChange={e => changeForm(e, 'phone')}  />
                                </RegisterFormContent>
                                <FormSpacer border />
                                <Title>
                                    { t("dashboard_password_title") }
                                </Title>
                                <FormSpacer marginTop={'16px'} />
                                <RegisterFormContent>
                                  <PasswordContent>
                                    <Input placeholder={ t("dashboard_form_password") } id={'password'} type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} />
                                    <Input placeholder={ t("dashboard_form_confirm_password") } id={'cpassword'} type="password" value={formValue('cpassword')} onChange={e => changeForm(e.target.value, 'cpassword')} />
                                    <FormSpacer noBorder />
                                    <PasswordValidation password={formValue('password')} setValidPassword={setValidPassword}/>
                                  </PasswordContent>
                                </RegisterFormContent>
                                <FormSpacer border />
                                <Title>
                                    { t("dashboard_register_level") }
                                </Title>
                                <FormSpacer />
                                <RegisterFormContent>
                                    <Select
                                        placeholder={ t("dashboard_settings_native_lang") }
                                        holder
                                        options={options?.native}
                                        value={formValue('native')} onChange={e => changeForm(e, 'native')} 
                                    />
                                    <Select
                                        placeholder={ t("dashboard_settings_learn_lang") }
                                        holder
                                        options={options?.learning}
                                        value={formValue('learning')} onChange={e => changeForm(e, 'learning')} 
                                    />
                                    <Select
                                        placeholder={ t("dashboard_form_level") }
                                        holder
                                        options={options?.levels}
                                        value={formValue('level')} onChange={e => changeForm(e, 'level')} 
                                    />
                                </RegisterFormContent>
                                <FormSpacer border />
                                <Title>
                                    { t("dashboard_register_subgender") }
                                </Title>
                                <CheckItems options={options?.subgenders} checked={checked} setChecked={setChecked} />
                                <FormSpacer border />
                                <CheckContainer>
                                    <Check success onChange={() => setTermChecked(!termChecked)}
                                        checked={termChecked}
                                        label={
                                            <Text>
                                                { t("dashboard_register_terms_1") } <Link onClick={() => window.open("https://technni.com/privacy/", "new")}>{ t("dashboard_register_terms_2") }</Link> { t("dashboard_register_and") } <Link onClick={() => window.open("https://technni.com/privacy/", "new")}>{ t("dashboard_register_terms_3") }</Link>
                                            </Text>
                                        }
                                    />
                                </CheckContainer>
                                <FormSpacer border />
                                <ButtonContainer end >
                                    <Button secondary width="50%" loading={loading} nospace onClick={next}
                                        endIcon="chevron-right"
                                    >{ t("dashboard_form_next") }</Button>
                                </ButtonContainer>

                            </RegisterContent>
                        </>
                    }
                    {
                        !(step === 1) ? null : <>
                            <SuccessContent>
                                <Icon icon="success" />
                                <FormSpacer />
                                <Container>
                                    <Title center>{steps?.[step]?.title}</Title>
                                    <FormText center>{steps?.[step]?.text}</FormText>
                                    <FormSpacer border />
                                    <ButtonContainer center>
                                        <Button width='50%' secondary nospace loading={loading} onClick={next} disabled={!validPassword}>{steps?.[step]?.label}</Button>
                                    </ButtonContainer>
                                </Container>
                            </SuccessContent>
                        </>
                    }
                </RegisterContainer>
            </ContainerUnauthenticated>
        </>
    );
}