import styled from 'styled-components'

export const DashboardHeaderContainer = styled.div.attrs({
})`           
    height: 88px;
    width:${props => props.saying || props.checkout ? '100%' : 'calc(100% - 252px)'};
    background: ${props => props.saying ? props.theme.palette.colors.almostwhite : props.theme.palette.colors.white};
    padding: ${props => props.saying ? '0 40px ' : '0 25px'};
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 991px) {
        width: 100%;
        padding: 10px 20px;
    }
`;

export const DashboardHeaderAction = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.colors.white};
    font-size: 15px;

    display: flex;
    justify-content: center;
    align-items: center;
`;


export const DashboardHeaderActionIcon = styled.img.attrs({
})`           
    margin-right: 10px;
    display: none;
    cursor: pointer;
    @media (max-width: 991px) {
        display: block;
    }
`;


export const HeaderContent = styled.div.attrs({
})`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

