import { CoreContext } from "context/CoreContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ReadPlans, ReadRankingByUserID, ReadUser, ReadWordsByUserId, ValidateUserPlanByUserID, ReadTestDriveByUserID } from "services/mobdict";
import { ReadObject, SaveObject } from "services/storage";
import { exposeMobdictError } from "utils";
import useWindowSize from "utils/useWindowSize";


export default function useController() {
    
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { width } = useWindowSize()
    const { words, setWords, ranking, setRanking, setOpened, setUser, setPlan, setPlans, fetchUser, language, testDrive, setTestDrive, checkout } = useContext(CoreContext)

    useEffect(() => {
        if (width <= 991) {
            setOpened(false)
        } else {
            setOpened(true)
        }
    }, [width])

    const fetchRanking = async (u, id) => {
        const result = await ReadRankingByUserID({ level: u?.level }, id)
        if(!exposeMobdictError(result, (!!result?.data?.ranking) )){
            // console.log("RANKING", result?.data)
            setRanking(result?.data)
            return result?.data;
        }
    }

    const fetchWords = async (id) => {
        const result = await ReadWordsByUserId(id)
        if(!exposeMobdictError(result, (!!result?.data?.id_idioma) )){
            // console.log("WORDS", result?.data)
            setWords(result?.data)
            return result?.data;
        }
    }

    const fetchPlan = async (auth) => {
        const result = await ValidateUserPlanByUserID({ tipo: auth?.tipo }, auth?.id)
        if(!exposeMobdictError(result, !( typeof result?.data === 'string') )){
            let valid = true ? result?.data?.valid === "true" : false
            setPlan(valid)
            return result?.data;
        } else {
            setPlan(false)
            return false;
        }
    }

    const fetchPlans = async () => {
        const result = await ReadPlans(language)
        if(!exposeMobdictError(result, !!( result?.data?.planos ) )){
            // console.log("PLANS", result?.data?.planos)
            setPlans(result?.data?.planos)
            return result?.data?.planos;
        }
    }

    const fecthTestDrive = async (auth) => {
        const result = await ReadTestDriveByUserID(auth?.id)
        if(!exposeMobdictError(result, !( typeof result?.data === 'string') )){
            // console.log("PLANS", result?.data?.planos)
            setTestDrive(result?.data)
            return result?.data;
        }
    }

    const init = async () => {
        const auth = await ReadObject('authentication')
        if(!auth?.id){ navigate('login'); return ; }
        if(checkout) { return ; }
        let plan = await fetchPlan(auth)

        var new_auth = null
        if (!exposeMobdictError(plan, !plan.tipo)) { 
          new_auth = { ...auth, tipo: plan.tipo }
          await SaveObject('authentication', new_auth)
        }
        
        if (new_auth?.tipo === "tstd") { await fecthTestDrive(auth) }
        const u = await fetchUser(auth?.id)
        
        fetchWords(auth?.id)
        fetchRanking(u, auth?.id)

    }
    
    useEffect(() => { init() ;}, [])
    useEffect(() => { fetchPlans() ;}, [language])
    
    return {
        words,
        ranking
    }
}