import React, { useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    FormTitle,
    FormText,
    FormSpacer,
    PasswordContent,
    PasswordContainer,
    SuccessContent
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import { DoResetPassword } from "services/authentication";
import { exposeMobdictError, exposeStrapiError } from "utils";
import { ButtonContainer, Icon, Title } from "ui/styled";
import { Container } from "reactstrap";
import PasswordValidation from "components/Form/PasswordValidation";
import ContainerAuthenticated from "containers/Authenticated";
import ContainerUnauthenticated from "containers/Unauthenticated";
import { CompleteForgot } from "services/mobdict";
import useI18n from "hooks/useI18n";

export default function CreatePassword() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    const params = new URLSearchParams(window.location.search)

    const { t } = useI18n(true, params.get('idioma') || 'portuguese')

    const [loading, setLoading] = useState(false)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }
    const [step, setStep] = useState(0)
    const [validPassword, setValidPassword] = useState(false)

    const valid = (verbose = false) => {

        if (!formValue('password') || !formValue('password').length) {
            if (verbose) { toast.error( t("dashboard_register_validation_password") ); }
            return false;
        }

        if (!formValue('cpassword') || !formValue('cpassword').length) {
            if (verbose) { toast.error( t("dashboard_register_validation_password_confirmation") ); }
            return false;
        }

        if (formValue('password') !== formValue('cpassword')) {
            if (verbose) { toast.error( t("dashboard_register_validation_not_match") ); }
            return false;
        }

        return true
    }

    const action = async () => {
        if (!valid(true) || loading) { return; }
        setLoading(true)

        const result = await CompleteForgot({
            token: params.get('token'),
            senha: formValue('password'),
            // passwordConfirmation: formValue('cpassword')
        })

        setLoading(false)

        if( !exposeMobdictError(result, (typeof result?.data !== 'string') )){
            toast.success(result?.message)
            setStep(step + 1);
        }
    }

    const completNext = () => {
        toast.success( t("dashboard_createpassword_step2_title") );
        navigate('login')
    }

    const steps = useMemo(() => [
        {
            title: t("dashboard_createpassword_step1_title"),
            text: t("dashboard_createpassword_step1_text"),
            labelNext: t("dashboard_register_step1_action"),
        },
        {
            title: t("dashboard_createpassword_step2_title"),
            text: t("dashboard_createpassword_step2_text"),
            label: t("dashboard_register_step2_action"),
        }
    ], [t]);

    const next = () => {
        if (step === steps.length - 1) {
            //save data
            navigate('Login')
            return;
        }
        setStep(step + 1);
    }
    
    const back = () => {
        if (step === 0) {
            navigate('Login')
            return;
        }
        setStep(step - 1);
    }

    return (
        <>
            <ContainerUnauthenticated free >
                <PasswordContainer>
                    {!(step === 0) ? null :
                        <>
                            <PasswordContent>
                                <Title hasIcon>
                                    <Icon icon='logo-mobdict' width={44} nomargin />
                                    {steps?.[step]?.title}</Title>
                                <FormText>{steps?.[step]?.text}</FormText>
                                <Input id={'password'} placeholder={t("dashboard_create_new_password_step1_text")} type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} />
                                <FormSpacer noBorder />
                                <Input id={'cpassword'} placeholder={t("dashboard_create_new_password_step2_text")} type="password" value={formValue('cpassword')} onChange={e => changeForm(e.target.value, 'cpassword')} />
                                <FormSpacer noBorder />
                                <PasswordValidation password={formValue('password')} setValidPassword={setValidPassword}/>
                                <FormSpacer />
                                <ButtonContainer end >
                                    <Button nospace width="50%" secondary between endIcon="chevron-right" loading={loading} onClick={action} disabled={!validPassword}>
                                        {steps?.[step]?.labelNext}
                                    </Button>
                                </ButtonContainer>
                            </PasswordContent>
                        </>
                    }
                    {
                        !(step === 1) ? null : <>
                            <SuccessContent>
                                <Icon icon="success" />
                                <Container>
                                    <Title center>{steps?.[step]?.title}</Title>
                                    <FormText center>{steps?.[step]?.text}</FormText>
                                    <FormSpacer />
                                    <ButtonContainer center>
                                        <Button width='50%' secondary nospace loading={loading} onClick={next} disabled={!validPassword}>{steps?.[step]?.label}</Button>
                                    </ButtonContainer>
                                </Container>
                            </SuccessContent>
                        </>
                    }
                </PasswordContainer>
            </ContainerUnauthenticated>
        </>
    );
}