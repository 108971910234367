import React, { useEffect, useState } from "react";  

import Header from 'components/Landpage/Header'
import { default as DashHeader } from 'components/Dashboard/Header'
import { ReadObject } from "services/storage";

import { 
    Content
} from "./styled"; 
import { ThemedComponent } from "ui/theme";

export default function ContainerLandpage({ children }){ 
  const [logged, setLogged] = useState(false)

  const init = () => {
      const authentication = ReadObject('authentication')
      if (authentication?.access_token) {
          setLogged(true)
      }
  }
    useEffect(() => { 
      init()
      window.scrollTo(0,0) ;
    },[])

    return ( 
        <ThemedComponent>
            <Content>
                {!logged ? (<Header />) : <DashHeader />}
                    { children }
            </Content>
        </ThemedComponent>
    );
}