import NewSaying from 'components/NewSaying'
import ProgressBar from 'components/ProgessBar'
import Response from 'components/Response'
import SayingBody from 'components/SayingBody'
import SayingError from 'components/SayingError'
import SuccessAndError from 'components/SuccesAndError'
import Success from 'components/Success'
import Writing from 'components/Writing'
import ContainerAuthenticated from 'containers/Authenticated'
import { CoreContext } from 'context/CoreContext'
import useI18n from 'hooks/useI18n'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SayingContainer, SayingContent } from 'screens/Saying/styled'
import { CreateDictByUserId, GetDictAudioReinforcement, ReadTextByUserID, SentDictByUserId, UpdateReforceByUserId } from 'services/mobdict'
import { ReadObject } from 'services/storage'
import { theme } from 'ui/theme-color'
import { exposeMobdictError } from 'utils'
const data = {
  text: "Um programador estava discutindo com um colega sobre a eficiência de suas funções. Para ilustrar seu ponto, ele disse: \"Eu sempre comparo o código a uma receita de bolo. Se o bolo não crescer, o problema pode estar na receita, ou então na maneira como você a está interpretando.\" O colega, um pouco confuso, respondeu: \"Então, você quer dizer que devemos sempre verificar se a receita está correta antes de adicionar os ovos?\" O programador sorriu e disse: \"Não, quero dizer que a culpa não está apenas no forno – talvez o problema esteja em como você está batendo a massa!\"",
  words: ["programador", "ilustrar", "receita", "forno"],
  backgroundColor: theme.palette.wrong.main,
};

export default function SayingReinforcement() { 

  const { noHeader, setNoHeader, setOpened, challenge, setChallenge, setReinforcement, handleStateReset, dict, setDict, toCorrect, setToCorrect, user, toCorrectReinforcement, setToCorrectReinforcement, setForm } = useContext(CoreContext)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [response, setResponse] = useState(false);
  
  const [loading, setLoading] = useState(false)
  const [challangeInfo, setChallangeInfo] = useState(null)

  const { t } = useI18n(false)

  const [audioReinforcement, setAudioReinforcement] = useState(null)

  useEffect(() => {
    setOpened(false)
    setNoHeader(false)
    setReinforcement(true)
  }, [])

  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const handleReinforcementFinish = () => {

    setError(false)
    setSuccess(false)
    setForm({})
    // setToCorrect(true)
    // setChallenge(false)
  }


  const handleReinforcementComplete = () => {

    // setError(false)
    setSuccess(true)
    setForm({})
    // setToCorrect(true)
  }

  const handleReinforcement = () => {
    // setToCorrect(false)
    // setChallenge(true)
  }

  const sayingChallengeItems = {
    label: response ? t("dict_finish") : t("dict_next"),
    writingAction: () => response ? handleReinforcementComplete() : setResponse(true),
  };

  const sayingItems = {
    label: t("dict_correct"),
    // writingAction: () => setToCorrect(true),
    writingAction: (t) => save(t),
  };
  
  const valid = (text) => {
    if ( (!text || !text.length)  ) {
        toast.error( t("lg-error-pc") ); 
        return false;
    } 
    return true
  }

  const save = async (text) => {
    if (!valid(text) ) { return; }
    if(!loading){
      setLoading(true)

        const result = await SentDictByUserId({
          texto: text,
          id_ditado: dict?.id_ditado,
          update_qtd: false,
          texto_reforco: toCorrect?.texto_reforco,
          tipo: 1
        }, user?.id)

        // console.log("resultSX", result)

        if( !exposeMobdictError(result,  !!(result?.data?.texto_correto) )){
          setError(!!result?.data?.texto_reforco?.length)
          setSuccess(!result?.data?.texto_reforco?.length)
          setToCorrect(result?.data)
        }
        setForm({})

        const challange = await ReadTextByUserID({ texto: toCorrect?.texto_reforco }, user?.id)
        
        // console.log("challange", challange)

        if( !exposeMobdictError(challange, !!( challange?.data?.texto ) )){
          setChallenge(challange?.data)
        }

      setLoading(false)
    }
  }

  const init = async (text) => {
    // console.log("dictX25", dict, toCorrect, text)
    setError(false)
    setSuccess(false)
    setForm({})
    if(!loading){
      setLoading(true)
      
      const reinforcement = await UpdateReforceByUserId({
        break_time: 0,
        id_ditado: dict?.id_ditado,
        texto: toCorrect?.texto_reforco,
        voz: dict?.voz
      }, user?.id)

      // console.log("REinforcement success", reinforcement)

      if( !exposeMobdictError(reinforcement, !!(reinforcement?.data?.audio_reforco) )){
        toast.success(reinforcement?.message)
        setToCorrectReinforcement(reinforcement?.data)
        initAudio(reinforcement?.data)
      }else{
        setLoading(false)
      }
    }

  }

  const initAudio = async (ar) => {
    // console.log("initX25 audio resource", ar)

    if(!loading && ar){
      setLoading(true)
      
      const reinforcement = await GetDictAudioReinforcement(ar?.audio_reforco)

      // console.log("Dict SOUND", reinforcement)

      if( !exposeMobdictError(reinforcement, !!(reinforcement?.url) )){
        setAudioReinforcement(reinforcement)
      }
        
      setLoading(false)
    }

  }

  const restart = async () => {

    if(!loading){

      setLoading(true)
        const auth = await ReadObject('authentication')
        const result = await CreateDictByUserId({
          tipo: auth?.tipo,
          break_time: user?.pausa || 9
        }, user?.id)
  
      // console.log("Dict", result)
  
      if( !exposeMobdictError(result, !!(result?.data?.voz) ) ){
        setError(false)
        setToCorrect(null)
        setDict(result?.data) 
        setChallenge(null)
        setForm({})
        setReinforcement(null)
        setOpened(false)
        navigate('saying')
      }
        
      setLoading(false)
    }
  }

  useEffect(() => { init() ;}, []) 

  return (
    <ContainerAuthenticated close saying noHeader={noHeader}>
      <SayingContainer>
        <SayingContent center={error}>
          {
            (error||success) ? <SuccessAndError toCorrect={toCorrect} error={error} action={handleReinforcementFinish} restart={restart} loading={loading}/>
              :
              challenge ?
                <>
                  <SayingBody {...sayingChallengeItems} response={response} challangeInfo={challangeInfo} />
                </>
                :
                <>
                  <SayingBody {...sayingItems} audioOriginal={audioReinforcement} />
                </>
          }
        </SayingContent>
      </SayingContainer>
    </ContainerAuthenticated>
  )
}
