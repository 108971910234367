import React from "react";

import {
    MoreInfosContainer,
    MoreInfosText,
    MoreInfosTitle,
    MoreInfosTitleContent,

} from './styled'
import VideoPlayer from "components/VideoPlayer";
import { Icon } from "ui/styled";
import useI18n from "hooks/useI18n";


export default function MoreInfos() {

    const { t } = useI18n(false)

    return (
        <>
            <MoreInfosContainer>
                <MoreInfosTitleContent>
                    <Icon icon="logo-mobdict" />
                    <MoreInfosTitle>
                        { t("landpage_more_title") }
                    </MoreInfosTitle>
                </MoreInfosTitleContent>
                <MoreInfosText>
                    { t("landpage_more_text") }
                </MoreInfosText>
                <VideoPlayer url="/videos/landpage.mp4" />

            </MoreInfosContainer>
        </>
    );
}