import React from 'react'
import { Icon, Title } from 'ui/styled'
import { ContactContainer, ContactUsContent, ContactUsFormTitle, ContactUsFormValues } from './styled'
import useI18n from 'hooks/useI18n';

export default function ContactUs() {

  const { t } = useI18n(false)

  const contactInfo = [
    {
      title: t("dashboard_talkus_email"),
      value: "techni@techni.com"
    },
    {
      title: t("dashboard_talkus_support_email"),
      value: "helpme@techni.com"
    },
    {
      title: "Whatsapp",
      value: "+55 11 91054-1902"
    },
    {
      title: t("dashboard_talkus_support_address"),
      value: "1636 Av. Paulista, Conj 5 Pavlh 11 Suites 1105/23-Bela vista - São Paulo - SP - 01310-299 - Brazil"
    },
    {
      title: t("dashboard_talkus_support_social"),
      icons: ["insta-black", "face-black", "linkedin-black"],
      links: ["https://www.instagram.com/mobdict", "https://www.facebook.com/mobdict", "https://www.linkedin.com/company/mobdict/"]
    }
  ];

  return (
    <>
      <ContactUsContent>
        <Title hasIcon>
          <Icon icon='logo-mobdict' width={44} nomargin />
          { t("dashboard_talkus_title") }
        </Title>
        {contactInfo.map((info, index) => (
          <ContactContainer key={index}>
            <ContactUsFormTitle>{info.title}</ContactUsFormTitle>
            <ContactUsFormValues>
              {info.icons ? info.icons.map((icon, index) => (
                <Icon key={index} icon={icon} pointer onClick={!info?.links?.[index] ? null : () => window.open(info?.links?.[index], "new")} />
              )) : info.value}
            </ContactUsFormValues>
          </ContactContainer>
        ))}
      </ContactUsContent>
    </>
  )
}
