import styled from 'styled-components';

export const VideoWrapper = styled.div.attrs({
})`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledVideo = styled.video`
  width: 100%;
  object-fit: contain;
  border-radius: 8px;

`;