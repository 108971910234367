import React, { useEffect, useState } from 'react'
import { CheckItem, CheckItemsContent } from './styled'
import Check from 'components/Form/Check'
import useI18n from 'hooks/useI18n';

export default function CheckItems({ options, checked, setChecked }) {
  
  const { t } = useI18n(false)

  const categorias = [
    ...(options||[]).map(m => m.id),
    // "Auto ajuda", "Conto", "Elegia", "Fábula", "Madrigal",
    // "Poesia", "Auto ajuda", "Biografia", "Crônica",
    // "Epitalâmico", "Farsa", "Novela", "Romance",
    // "True crime", "Comédia", "Ecloga", "Epopéia",
    // "Ficção", "Ode", "Técnica", "Auto ajuda"
  ];

  const handleCheck = (id) => {
    const index = checked.indexOf(id);
    if (index > -1) {
      setChecked(checked.filter(item => item !== id))
    } else {
      setChecked([...checked, id])
    }
  }

  useEffect(() => {
    if(checked?.includes('Infantojuvenil') && checked?.length > 1){
      setChecked(['Infantojuvenil'])
    }
  }, [checked])

  return (
    <CheckItemsContent>
      {
        categorias.map((categoria, index) => (
          <CheckItem key={index}>
            <Check id={index} label={t(categoria)} disabled={checked?.includes('Infantojuvenil') && categoria !== "Infantojuvenil"}
              checked={checked?.includes(categoria)}
              onChange={() => handleCheck(categoria)}
              success
            />
          </CheckItem>
        ))
      }
    </CheckItemsContent>
  )
}
