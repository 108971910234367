export const static_translation = {
    "landpage_banner_balloon": "Améliorez une nouvelle langue !",
    "landpage_flags_title": "Améliorez une langue avec Mobdict®",
    
    "landpage_how_works_title": "Notre plus grande satisfaction est de vous aider à apprendre une nouvelle langue ❤️",
    "landpage_how_works_text": "Nous sommes passionnés par les langues et l'éducation. Notre objectif est de rendre l'apprentissage accessible et amusant pour tous, en utilisant une approche innovante basée sur les dictées.",
    "landpage_how_works_action": "Je veux en savoir plus, commençons",
    
    "landpage_learn_title": "Apprenez beaucoup plus 😎",
    "landpage_learn_text": "Notre méthode d'enseignement se concentre sur la pratique des langues à travers les dictées, une technique qui combine compréhension orale, lecture et pratique de l'écriture pour améliorer vos compétences linguistiques de manière naturelle et efficace.",
    "landpage_learn_action": "Commencer maintenant",
    
    "landpage_write_title": "Écrivez à la main et apprenez plus avec Mobdict®",
    "landpage_write_text": "Écrire à la main peut améliorer la rétention et la compréhension du vocabulaire, car cela implique des processus cognitifs plus profonds. Envoyez vos manuscrits à Mobdict® en utilisant l'application Mobdict® Image Sender.",
    
    "landpage_free_title": "Essayez gratuitement 🥹",
    "landpage_free_text": "Vous pouvez pratiquer la langue que vous étudiez, à votre niveau actuel, en testant Mobdict® gratuitement pendant une semaine et en réalisant jusqu'à 7 dictées pour voir votre vocabulaire grandir avec chaque défi.",
    "landpage_free_action": "Je veux essayer",
    
    "landpage_more_title": "Apprenez de la meilleure manière 🎬",
    "landpage_more_text": "Regardez notre vidéo pour comprendre comment l'apprentissage des langues par le biais de dictées peut vous bénéficier.",
    
    "faq_title": "FAQ - Questions fréquentes",
    "faq_question_1": "Qui peut participer au cours ?",
    "faq_answer_1": "Pour savoir qui peut participer à un cours, vous devez vérifier les exigences spécifiques établies par l'institution ou les responsables du cours.",
    "faq_question_2": "Notre cours est idéal pour les débutants et pour ceux qui souhaitent améliorer leurs compétences linguistiques.",
    "faq_answer_2": "Notre cours est idéal pour les débutants et pour ceux qui souhaitent améliorer leurs compétences linguistiques. Ainsi, toute personne qui commence ou souhaite développer davantage ses compétences linguistiques peut participer.",
    "faq_question_3": "Comment fonctionne la méthode d'apprentissage par dictées ?",
    "faq_answer_3": "La méthode d'apprentissage par dictées est une technique qui aide à améliorer les compétences d'écoute, l'orthographe et la compréhension des textes.",
    "faq_question_4": "Quelle est la durée du cours ?",
    "faq_answer_4": "La durée du cours peut varier en fonction de plusieurs facteurs, tels que l'institution qui l'offre, le nombre total d'heures de cours et la fréquence des cours.",
    
    "footer_talk_us": "Contactez-nous",
    "footer_support": "Support",
    "footer_all_rights": "Tous droits réservés",
    
    "lg-google-auth": "Entrez le code d'authentification Google Authenticator",

    "dashboard_home_see_plans": "Voir les plans",
    "dashboard_home_dicts_done": "réalisés",
    "dashboard_home_dicts_plan": "Dictées achetées",
    "dashboard_home_dicts_test": "Période d'essai",
    "dashboard_home_dicts_days": "jours restants",
    "dashboard_home_dicts_test_done": "Période d'essai terminée",
    "dashboard_home_dicts_dict": "Dictées",
    
    "dashboard_home_learning": "Je suis en train d'apprendre",
    "dashboard_home_level": "Niveau",
    "dashboard_home_cta": "Commençons la dictée",
    
    
    
    "dashboard_home_ranking_title": "Classement",
    "dashboard_home_ranking_position": "Pos.",
    "dashboard_home_ranking_name": "Nom",
    "dashboard_home_ranking_points": "Points",
    "dashboard_home_ranking_see_more": "Voir plus",

    "dashboard_side_home": "Accueil",
    "dashboard_side_ranking": "Classement",
    "dashboard_side_buy": "Acheter des crédits",
    "dashboard_side_account": "Mon compte",
    "dashboard_side_settings": "Paramètres",
    "dashboard_side_faq": "FAQ",
    "dashboard_side_talk_us": "Contactez-nous",
    "dashboard_side_exit": "Déconnexion",
    
    "dashboard_form_password_validation": "Le nouveau mot de passe et la confirmation ne sont pas identiques",

    "dashboard_form_name": "Nom complet",
    "dashboard_form_nickname": "Choisissez votre pseudonyme",
    "dashboard_form_email": "Email",
    "dashboard_form_phone": "Téléphone",
    "dashboard_form_password": "Mot de passe",
    "dashboard_form_level": "Niveau",
    "dashboard_form_confirm_password": "Confirmer le mot de passe",

    "dashboard_form_account_title": "Mon compte",
    "dashboard_form_password_title": "Changer le mot de passe",
    
    "dashboard_form_cancel": "Annuler",
    "dashboard_form_next": "Continuer",
    "dashboard_form_save": "Sauvegarder",
    "dashboard_form_subgender": "Sélectionner les sous-genres",
    
    "dashboard_settings_title": "Paramètres",
    "dashboard_settings_2fa": "Activer l'authentification à deux facteurs",
    "dashboard_settings_adjust_pause": "Ajuster la pause dans la dictée",
    "dashboard_settings_pause": "Pause",
    "dashboard_settings_subgender": "Réinitialiser les sous-genres favoris (min. 3) :",
    "dashboard_settings_define": "Définir",
    "dashboard_settings_default_lang": "Langue par défaut",
    "dashboard_settings_native_lang": "Langue maternelle",
    "dashboard_settings_learn_lang": "Langue à apprendre",
    "dashboard_settings_recurrency": "Abonnement récurrent",
    
    
    "dashboard_dict_congrats": "Félicitations !",
    "dashboard_dict_added_increase": "Vous avez ajouté plus",
    "dashboard_dict_added_words": "de mots à votre vocabulaire.",
    "dashboard_dict_restart": "Démarrer une nouvelle dictée",
    "dashboard_dict_back_homepage": "Retour à la page d'accueil",
    

    "dashboard_dict_wrong_words": "Les mots sont incorrects",
    "dashboard_dict_wrong_omit": "Mots omis",
    "dashboard_dict_wrong_finish": "Terminer",
    "dashboard_dict_wrong_challange": "Défi",
    "dashboard_dict_wrong_reinforcement": "Faire une dictée de renforcement",
    
    "dashboard_dict_translate": "Traduction",
    "dashboard_dict_see_translate": "Voir la traduction",
    
    "dashboard_dict_writing_title": "Tapez le texte",
    "dashboard_dict_writing_handed": "Écrire à la main",
    "dashboard_dict_writing_special": "Caractères spéciaux",
    
    "dashboard_dict_writing_step_1": "1. Téléchargez l'application Mobdict Image Sender depuis l'App Store ou le Play Store et connectez-vous.",
    "dashboard_dict_writing_step_2": "2. Écrivez la dictée que vous avez entendue sur un papier et prenez une photo.",
    "dashboard_dict_writing_step_3": "3. Envoyez l'image via l'application et cliquez sur le bouton à côté pour finaliser.",
    "dashboard_dict_writing_step_action": "J'ai envoyé",
    "dashboard_dict_writing_step_confirm": "J'ai vérifié, Corriger",
    
    "dashboard_dict_writing_progress": "Votre progression",
    "dashboard_dict_writing_meta": "Objectif",
    "dashboard_dict_writing_words": "mots",
    
    "dashboard_register_title": "Entrez vos informations",
    "dashboard_register_subtitle": "Indiquez vos informations personnelles",
    
    "dashboard_password_title": "Créez votre mot de passe",
    "dashboard_register_level": "Sélectionnez la langue et le niveau",
    "dashboard_register_subgender": "Sélectionnez le sous-genre",
    
    "dashboard_register_terms_1": "En sélectionnant et en continuant, vous acceptez nos",
    "dashboard_register_terms_2": "Conditions d'utilisation",
    "dashboard_register_and": "et",
    "dashboard_register_terms_3": "Politique de confidentialité",
    
    "dashboard_register_validation_not_match": "Le nouveau mot de passe et la confirmation ne sont pas identiques",
    "dashboard_register_validation_accept_terms": "Acceptez les conditions",
    "dashboard_register_validation_email": "Indiquez l'email",
    "dashboard_register_validation_password": "Indiquez le nouveau mot de passe",
    "dashboard_register_validation_password_confirmation": "Indiquez la confirmation du nouveau mot de passe",
    "dashboard_register_successfull": "Compte créé avec succès",
    
    "dashboard_register_step1_title": "Entrez vos informations",
    "dashboard_register_step1_text": "Indiquez vos informations personnelles",
    "dashboard_register_step1_action": "Continuer",
    
    "dashboard_register_step2_title": "Inscription terminée avec succès",
    "dashboard_register_step2_text": "Merci pour votre inscription.",
    "dashboard_register_step2_action": "Fermer",
    "dashboard_register_step2_back": "Retour",

    "dashboard_forgot_step1_title": "Mot de passe oublié ?",
    "dashboard_forgot_step1_text": "Entrez votre email enregistré pour récupérer votre mot de passe",
    "dashboard_forgot_step2_title": "Vérifiez votre email",
    "dashboard_forgot_step2_text": "Accédez au lien de récupération qui a été envoyé à votre email enregistré",
    
    "dashboard_createpassword_step1_title": "Nouveau mot de passe",
    "dashboard_createpassword_step1_text": "Entrez un nouveau mot de passe pour votre compte",
    "dashboard_createpassword_step2_title": "Nouveau mot de passe créé avec succès",
    "dashboard_createpassword_step2_text": "Vous pouvez maintenant vous connecter avec votre nouveau mot de passe",

    "login_mfa": "À l'aide de l'application Google Authenticator ou d'une autre application d'authentification, scannez le QRCode ci-dessous pour générer votre token",


    "register_validation_subgender": "Sélectionnez au moins 3 sous-genres pour continuer",
    "dict_word_bellow": "Utilisez les mots ci-dessous pour compléter le texte",
    "dict_reinforcement": "Dictée de renforcement",
    "dict_a_newdict": "Commencer une nouvelle dictée",
    "dict_by": "Dicté par",
    "dict_finish": "Terminer",
    "dict_next": "Continuer",
    "dict_correct": "Corriger",
    
    "Autoajuda": "Auto-assistance",
    "Biografia": "Biographie",
    "Comédia": "Comédie",
    "Conto": "Conte",
    "Crônica": "Chronique",
    "Ecologia": "Écologie",
    "Epopeia": "Épopée",
    "Fábula": "Fable",
    "Farsa": "Farce",
    "Ficção": "Fiction",
    "Infantojuvenil": "Littérature jeunesse",
    "Novela": "Nouvelle",
    "Poesia": "Poésie",
    "Romance": "Roman",
    "Suspense": "Suspense",
    "Tragédia": "Tragédie",
    "True Crime": "True Crime"

}

