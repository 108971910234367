import React, { useContext, useEffect, useState } from "react";

import {
    FormSpacer,
    HomeContent,
    Input,
    RangeLabel,
    SettingsContent,
    SettingsContentBackground,
    SettingsOption,
    SettingsOptionRange,
    SettingsOptions,
    SettingsOptionTitle,
    SettingsOptionValue
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import useWindowSize from "utils/useWindowSize";
import { CoreContext } from "context/CoreContext";
import { ButtonContainer, Icon, Title } from "ui/styled";
import Button from "components/Form/Button";
import Toggle from "components/Form/Toggle";
import Select from "components/Form/Select";
import { languages, optionize, optionizeArray, optionsLevel } from "utils/options";
import { toast } from "react-toastify";
import useI18n from "hooks/useI18n";
import { ReadInfos, UpdateUser, UpdatePauseByUserId, CancelSubscriptionByUserID, ReadPlanStatusByUserID } from "services/mobdict";
import { exposeMobdictError } from "utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Settings() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { width } = useWindowSize()

    const { setOpened, setModal, user, plan, fetchUser, language } = useContext(CoreContext)
    
    const [stops, setStops] = useState(user?.pausa || 9)

    const [loading, setLoading] = useState(false)
    const [active2ef, setActive2ef] = useState(user?.mfa === "True")
    const [activeRecorrent, setActiveRecorrent] = useState(plan?.recurrent || false)
    const [changed, setChanged] = useState(false)

    const { t } = useI18n(false)
    
    const [options, setOptions] = useState(null)

    const [form, setForm] = useState({ native: user?.id_idioma_nativo, learning: user?.id_idioma, level: optionsLevel.find(f => f.title === user?.level)?.id })
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    useEffect(() => {
        if (width <= 991) {
            setOpened(false)
        } else {
            setOpened(true)
        }
    }, [width])

    const valid = (verbose = false) => { 

        return true
    }

    const save = async () => {
        if(!valid(true) || loading){ return ;}
        setLoading(true)

            const payload = {
                nome: user?.nome,
                username: user?.username,
                email: user?.email,
                tel: user?.tel,
                tel_pais: "Brazil (Brasil)",
      
                subgeneros: user?.subgen_fav,

                pausa: parseInt(stops || 9),
                recorrencia: activeRecorrent ? 'True' : 'False',
                
                idioma_nativo: form?.native,
                idioma_default: form?.learning,
                "level": form?.level,
                mfa: active2ef ? 'True' : 'False',

                // pauses ?
                // recurrence ?
            }

            const result = await UpdateUser(payload, user?.id )

            if( !exposeMobdictError(result, !( typeof result?.data === 'string') )){
                toast.success(result?.message)
                await fetchUser(user?.id)
                history.goBack()
            }

            const pause_result = await UpdatePauseByUserId({ break_time: stops }, user?.id)

            if( !exposeMobdictError(pause_result, !( typeof pause_result?.data === 'string') )){
                toast.success(pause_result?.message)
                await fetchUser(user?.id)
            } else {
                toast.error(pause_result?.message)
            }

            if (changed) {
              const recurrence_result = await CancelSubscriptionByUserID({ active: !activeRecorrent}, user?.id)

              if( !exposeMobdictError(recurrence_result, !( typeof recurrence_result?.data === 'string') )){
                  toast.success(recurrence_result?.message)
                  await fetchUser(user?.id)
              }
            }

        setLoading(false)
    }

    const init = async () => {
        const result = await ReadInfos(language)
        if( !exposeMobdictError(result, (!!result?.data?.idiomas_aprendizado) )){
            setOptions({
                native: optionize(result?.data?.idiomas_nativos) || [],
                learning: optionize(result?.data?.idiomas_aprendizado) || [],
                subgenders: optionizeArray(result?.data?.subgeneros) || [],
                levels: [ ...optionsLevel ]
            })
        }

        const recurrence_result = await ReadPlanStatusByUserID(user?.id)
        console.log(recurrence_result)
        if( !exposeMobdictError(recurrence_result, !( typeof recurrence_result?.data === 'string') )){ 
              setActiveRecorrent(recurrence_result?.data?.recorrencia === "True")
          }
    }

    useEffect(() => { init() ;}, [])

    return (
        <>
            <ContainerAuthenticated>
                <HomeContent>
                    <SettingsContentBackground>
                        <SettingsContent>
                            <Title hasIcon>
                                <Icon icon='logo-mobdict' nomargin width={44} />
                                { t("dashboard_settings_title") }
                            </Title>
                            <FormSpacer />
                            <SettingsOptions>
                                <SettingsOption>
                                    <SettingsOptionTitle>
                                    { t("dashboard_settings_2fa") }
                                    </SettingsOptionTitle>
                                    <SettingsOptionValue>
                                        <Toggle
                                            checked={active2ef}
                                            color={'success'}
                                            onChange={() => setActive2ef(!active2ef)}
                                        />
                                    </SettingsOptionValue>
                                </SettingsOption>
                                <SettingsOption>
                                    <SettingsOptionTitle>
                                        { t("dashboard_settings_adjust_pause") }
                                    </SettingsOptionTitle>
                                    <SettingsOptionValue>
                                        <SettingsOptionRange>
                                            <RangeLabel>{ t("dashboard_settings_pause") }</RangeLabel>
                                            <RangeLabel>9-{stops}s</RangeLabel>
                                        </SettingsOptionRange>
                                        <Input
                                            type="range"
                                            transparent
                                            min="9"
                                            max="15"
                                            step="1"
                                            value={stops}
                                            onChange={e => setStops(e.target.value)}
                                        />
                                    </SettingsOptionValue>
                                </SettingsOption>
                                <SettingsOption>
                                    <SettingsOptionTitle>
                                        { t("dashboard_settings_subgender") }
                                    </SettingsOptionTitle>
                                    <SettingsOptionValue>
                                        <Button secondary width="240px" outline colorBlack 
                                            nospace
                                            onClick={() => setModal({ type: 'subgenres', items: (options?.subgenders||[]), selected: (user?.subgen_fav||[]) })}
                                        >{ t("dashboard_settings_define") }</Button>
                                    </SettingsOptionValue>
                                </SettingsOption>
                                <SettingsOption>
                                    <SettingsOptionTitle>
                                        { t("dashboard_settings_default_lang") }
                                    </SettingsOptionTitle>
                                    <SettingsOptionValue>
                                        <Select
                                            placeholder=' '
                                            options={options?.native}
                                            value={formValue('native')} onChange={e => changeForm(e, 'native')} 
                                        />
                                    </SettingsOptionValue>
                                </SettingsOption>
                                <SettingsOption>
                                    <SettingsOptionTitle>
                                        { t("dashboard_settings_learn_lang") }
                                    </SettingsOptionTitle>
                                    <SettingsOptionValue>
                                        <Select
                                            placeholder=' '
                                            options={options?.learning}
                                            value={formValue('learning')} onChange={e => changeForm(e, 'learning')} 
                                        />
                                    </SettingsOptionValue>
                                </SettingsOption>

                                <SettingsOption>
                                    <SettingsOptionTitle>
                                        { t("dashboard_form_level") }
                                    </SettingsOptionTitle>
                                    <SettingsOptionValue>
                                    <Select
                                            placeholder={' '}
                                            options={options?.levels}
                                            value={formValue('level')} onChange={e => changeForm(e, 'level')} 
                                        />
                                    </SettingsOptionValue>
                                </SettingsOption>

                                <SettingsOption>
                                    <SettingsOptionTitle>
                                        { t("dashboard_settings_recurrency") }
                                    </SettingsOptionTitle>
                                    <SettingsOptionValue>
                                        <Toggle
                                            checked={activeRecorrent}
                                            color={'success'}
                                            onChange={() => {
                                              setActiveRecorrent(!activeRecorrent)
                                              setChanged(true)
                                            }}
                                        />
                                    </SettingsOptionValue>
                                </SettingsOption>
                            </SettingsOptions>

                            <FormSpacer border />
                            <ButtonContainer end space >
                                <Button secondary width="144px" loading={loading} nospace onClick={save}
                                >{ t("dashboard_form_next") }</Button>
                            </ButtonContainer>

                        </SettingsContent>
                    </SettingsContentBackground>
                </HomeContent>
            </ContainerAuthenticated>
        </>
    );
}