export const static_translation = {
    "landpage_banner_balloon": "提升一门新语言！",
    "landpage_flags_title": "通过 Mobdict® 提升语言",
    
    "landpage_how_works_title": "我们的最大满意就是帮助您学习一门新语言 ❤️",
    "landpage_how_works_text": "我们对语言和教育充满热情。我们的目标是通过一种基于听写的创新方法，使学习变得更容易、更有趣。",
    "landpage_how_works_action": "我想了解更多，开始吧",
    
    "landpage_learn_title": "学到更多 😎",
    "landpage_learn_text": "我们的教学方法专注于通过听写练习语言，这是一种将听力理解、阅读和写作练习结合起来的技巧，可以自然有效地提升您的语言能力。",
    "landpage_learn_action": "立即开始",
    
    "landpage_write_title": "手写并通过 Mobdict® 学到更多",
    "landpage_write_text": "手写可以提高词汇的记忆和理解，因为它涉及更深层的认知过程。使用 Mobdict Image Sender 应用程序将您的手写稿件发送到 Mobdict。",
    
    "landpage_free_title": "免费试用 🥹",
    "landpage_free_text": "您可以在当前水平下免费试用 Mobdict® 一周，练习您正在学习的语言，并完成最多 7 次听写，观察您的词汇量如何随着每一次挑战而增长。",
    "landpage_free_action": "我想试试",
    
    "landpage_more_title": "以最佳方式学习 🎬",
    "landpage_more_text": "观看我们的视频，了解通过听写学习语言如何使您受益。",
    
    "faq_title": "常见问题",
    "faq_question_1": "谁可以参加课程？",
    "faq_answer_1": "要了解谁可以参加课程，您需要查看机构或课程负责人制定的具体要求。",
    "faq_question_2": "我们的课程非常适合初学者以及希望提升语言能力的人。",
    "faq_answer_2": "我们的课程非常适合初学者以及希望提升语言能力的人。因此，任何刚开始学习或希望进一步提高语言能力的人都可以参加。",
    "faq_question_3": "听写学习方法是如何工作的？",
    "faq_answer_3": "听写学习方法是一种帮助提高听力技能、拼写和文本理解的技巧。",
    "faq_question_4": "课程的持续时间是多少？",
    "faq_answer_4": "课程的持续时间可能会因多种因素而异，例如提供课程的机构、总课时数和课程的频率。",
    
    "footer_talk_us": "联系我们",
    "footer_support": "支持",
    "footer_all_rights": "版权所有",
    
    "lg-google-auth": "输入 Google Authenticator 代码",

    "dashboard_home_see_plans": "查看计划",
    "dashboard_home_dicts_done": "已完成",
    "dashboard_home_dicts_plan": "已购买的听写",
    "dashboard_home_dicts_test": "试用期",
    "dashboard_home_dicts_days": "剩余的天数",
    "dashboard_home_dicts_test_done": "试用期结束",
    "dashboard_home_dicts_dict": "听写",
    
    "dashboard_home_learning": "我正在学习",
    "dashboard_home_level": "级别",
    "dashboard_home_cta": "开始听写",
    
    "dashboard_plan_title": "订阅我们的计划之一",
    "dashboard_plan_subtitle": "每年计费",
    "dashboard_plan_pop": "最受欢迎",
    "dashboard_plan_1": "基础计划",
    "dashboard_plan_2": "中级计划",
    "dashboard_plan_3": "高级计划",
    "dashboard_plan_dict": "谚语",
    "dashboard_plan_action": "订阅",
    "dashboard_plan_currency": "¥",
    
    "dashboard_talkus_title": "联系我们",
    "dashboard_talkus_email": "电子邮件",
    "dashboard_talkus_support_email": "技术支持",
    "dashboard_talkus_support_address": "地址",
    "dashboard_talkus_support_social": "社交媒体",
    "dashboard_talkus_about_title": "关于我们",
    "dashboard_talkus_about_text": "在 Mobdict，使用谚语使您的语言学习变得更简单、更有趣！这些小小的智慧珠子提供了一种独特的方式来吸收文化和语言。当你学习一门新语言时，发现那些反映母语者思维和生活方式的谚语。每个表达都带来了宝贵的教训和文化背景，帮助你掌握细微差别，使你的表达更加自然。",

    
    "dashboard_home_ranking_title": "排名",
    "dashboard_home_ranking_position": "名次",
    "dashboard_home_ranking_name": "姓名",
    "dashboard_home_ranking_points": "积分",
    "dashboard_home_ranking_see_more": "查看更多",

    "dashboard_side_home": "首页",
    "dashboard_side_ranking": "排名",
    "dashboard_side_buy": "购买积分",
    "dashboard_side_account": "我的账户",
    "dashboard_side_settings": "设置",
    "dashboard_side_faq": "常见问题",
    "dashboard_side_talk_us": "联系我们",
    "dashboard_side_exit": "退出",

    "dashboard_form_password_validation": "新密码和确认密码不一致",

    "dashboard_form_name": "全名",
    "dashboard_form_nickname": "选择你的用户名（昵称）",
    "dashboard_form_email": "电子邮件",
    "dashboard_form_phone": "手机",
    "dashboard_form_password": "密码",
    "dashboard_form_level": "级别",
    "dashboard_form_confirm_password": "重复密码",

    "dashboard_form_account_title": "我的账户",
    "dashboard_form_password_title": "更改密码",
    
    "dashboard_form_cancel": "取消",
    "dashboard_form_next": "继续",
    "dashboard_form_save": "保存",
    "dashboard_form_subgender": "选择子类别",
    
    "dashboard_settings_title": "设置",
    "dashboard_settings_2fa": "启用双重认证",
    "dashboard_settings_adjust_pause": "调整听写暂停",
    "dashboard_settings_pause": "暂停",
    "dashboard_settings_subgender": "重置喜爱的子类别（最少3个）：",
    "dashboard_settings_define": "定义",
    "dashboard_settings_default_lang": "默认语言",
    "dashboard_settings_native_lang": "母语",
    "dashboard_settings_learn_lang": "学习语言",
    "dashboard_settings_recurrency": "定期订阅",
    
    
    "dashboard_dict_congrats": "恭喜！",
    "dashboard_dict_added_increase": "你已将更多",
    "dashboard_dict_added_words": "单词添加到你的词汇中。",
    "dashboard_dict_restart": "开始新的听写",
    "dashboard_dict_back_homepage": "返回首页",
    

    "dashboard_dict_wrong_words": "单词不正确",
    "dashboard_dict_wrong_omit": "遗漏的单词",
    "dashboard_dict_wrong_finish": "完成",
    "dashboard_dict_wrong_challange": "挑战",
    "dashboard_dict_wrong_reinforcement": "进行复习听写",
    
    "dashboard_dict_translate": "翻译",
    "dashboard_dict_see_translate": "查看翻译",
    
    "dashboard_dict_writing_title": "输入文本",
    "dashboard_dict_writing_handed": "手写",
    "dashboard_dict_writing_special": "特殊字符",
    
    "dashboard_dict_writing_step_1": "1. 从 App Store 或 Play Store 下载 Mobdict Image Sender 应用并登录。",
    "dashboard_dict_writing_step_2": "2. 在纸上写下你听到的听写内容，并拍一张照片。",
    "dashboard_dict_writing_step_3": "3. 通过应用发送图片，并点击旁边的按钮完成。",
    "dashboard_dict_writing_step_action": "我已发送",
    "dashboard_dict_writing_step_confirm": "我已检查，修正",
    
    "dashboard_dict_writing_progress": "你的进度",
    "dashboard_dict_writing_meta": "目标",
    "dashboard_dict_writing_words": "单词",
    
    "dashboard_register_title": "输入你的信息",
    "dashboard_register_subtitle": "提供你的注册信息",
    
    "dashboard_password_title": "创建密码",
    "dashboard_register_level": "选择语言和级别",
    "dashboard_register_subgender": "选择子类别",
    
    "dashboard_register_terms_1": "选择并继续，即表示你同意我们的",
    "dashboard_register_terms_2": "服务条款",
    "dashboard_register_and": "和",
    "dashboard_register_terms_3": "隐私政策",
    
    "dashboard_register_validation_not_match": "新密码和确认密码不一致",
    "dashboard_register_validation_accept_terms": "接受条款",
    "dashboard_register_validation_email": "提供电子邮件",
    "dashboard_register_validation_password": "提供新密码",
    "dashboard_register_validation_password_confirmation": "提供新密码确认",
    "dashboard_register_successfull": "账户创建成功",
    
    "dashboard_register_step1_title": "输入你的信息",
    "dashboard_register_step1_text": "提供你的注册信息",
    "dashboard_register_step1_action": "继续",
    
    "dashboard_register_step2_title": "注册成功完成",
    "dashboard_register_step2_text": "感谢你的注册。",
    "dashboard_register_step2_action": "关闭",
    "dashboard_register_step2_back": "返回",

    "dashboard_forgot_step1_title": "忘记密码了？",
    "dashboard_forgot_step1_text": "输入你注册的电子邮件以找回密码",
    "dashboard_forgot_step2_title": "检查你的电子邮件",
    "dashboard_forgot_step2_text": "访问发送到你注册电子邮件的恢复链接",
    
    "dashboard_createpassword_step1_title": "新密码",
    "dashboard_createpassword_step1_text": "输入你的账户的新密码",
    "dashboard_createpassword_step2_title": "新密码创建成功",
    "dashboard_createpassword_step2_text": "现在你可以用新密码登录",
}
