import React, { useContext, useEffect } from "react";

import {
    HomeContent
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import HomeBody from "components/Dashboard/HomeBody";
import Ranking from "components/Ranking";
import useWindowSize from "utils/useWindowSize";
import { CoreContext } from "context/CoreContext";
import useController from "./controller";

export default function DashboardHome() {

    return (
        <>
            <ContainerAuthenticated>
                <HomeContent>
                    <HomeBody />
                    <Ranking />
                </HomeContent>
            </ContainerAuthenticated>
        </>
    );
}