import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './router'; // MOD
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import { CoreState } from './context/CoreContext'

import { GoogleOAuthProvider } from '@react-oauth/google';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-TZZV366V'
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="773707346976-58o3mbpv88latgmo77pddp78d15u4co0.apps.googleusercontent.com">
      <CoreState>
        <App />
        <ToastContainer />
      </CoreState>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); 