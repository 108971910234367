import React, { useState } from "react";

import { Container } from 'reactstrap';

import {
    ButtonContainer,
    DownloadButton,
    HowWorksBanner,
    HowWorksContainer,
    HowWorksContent,
    Logo,
    Text,
    Title,

} from './styled'
import Button from "components/Form/Button";
import { Icon } from "ui/styled";

export default function HowWorks({ banner, title, text, label, reverse, light, big, small, download, bannerBig, smallContainer, click }) {

    const [activeTab, setActive] = useState(1)


    return (
        <>
            <HowWorksContainer reverse={reverse} light={light} small={smallContainer}>
                <HowWorksBanner banner={banner} small={small} big={bannerBig} />
                <HowWorksContent big={big}>
                    <Logo />
                    <Title>
                        {title}
                    </Title>
                    <Text>
                        {text}
                    </Text>
                    {
                        download ?
                            <ButtonContainer>
                                <DownloadButton onClick={() => window.open("#", "new")}>
                                    <Icon icon='app-store' nomargin width={160} />
                                </DownloadButton>
                                <DownloadButton onClick={() => window.open("https://play.google.com/store/apps/details?id=com.mobdict.app&hl=pt_BR", "new")}>
                                    <Icon icon='google-play' nomargin width={160} />
                                </DownloadButton>
                            </ButtonContainer>
                            :
                            <Button secondary onClick={click}>
                                {label}
                            </Button>
                    }
                </HowWorksContent>
            </HowWorksContainer>
        </>
    );
}