import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {
    IconProfile,
    InputContainer,
    MaterialLabel,
    MaterialSelect,
    SelectContainer,
    SelectText,
    StyledInputLabel
} from "./styled";
import { ThemedComponent } from "ui/theme";

export const Select = ({ placeholder, options, small, value, onChange, id, holder, startIcons, width, fitContent, border }) => {
    const [selectedValue, setSelectedValue] = useState(options[0]?.id || '');
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        if (value !== undefined) {
            setSelectedValue(value);
        }
    }, [value]);

    const toggleOpen = () => {
        setOpened(!opened);
    }

    const optionAction = item => {
        const selectedId = item.target.value;
        setSelectedValue(selectedId);
        if (onChange && typeof onChange === 'function') {
            onChange(selectedId);
        }
        toggleOpen();
    }

    const selectedOption = options.find(option => option.id === selectedValue);

    return (
        <>
            <ThemedComponent>
                <InputContainer fitContent={fitContent}>
                    {holder && <StyledInputLabel id={id} sx={{ fontSize: '16px' }}>{placeholder}</StyledInputLabel>}
                    <MaterialSelect
                        width={width}
                        variant="standard"
                        border={border}
                        disableUnderline
                        small={small}
                        labelId={id}
                        id={`select-${id}`}
                        value={selectedValue}
                        onChange={optionAction}
                        color="secondary"
                        startAdornment={
                            startIcons && startIcons

                        }

                    >
                        {options?.map((item, key) => (
                            <MenuItem key={key} value={item.id}>
                                {item.title}
                            </MenuItem>
                        ))}
                    </MaterialSelect>
                </InputContainer>
            </ThemedComponent>
        </>
    )
}

Select.propTypes = {
    placeholder: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.object,
    small: PropTypes.bool,
    secondary: PropTypes.bool,
};

Select.defaultProps = {
    placeholder: 'Cenario',
    options: [],
    value: undefined,
    small: false,
    secondary: false,
    onChange: undefined,
};

export default Select;