import styledCmp from 'styled-components';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

export const MaterialSelect = styled(Select)(({ theme, ...props }) => ({
  gap: '5px',
  fontSize: '14px',
  height: props.small ? '40px' : '48px',
  width: props.width ? props.width : '100%',
  padding: '10px 14px',
  border: props.border && `2px solid ${theme.palette.secondary.main}`,
  backgroundColor: props.border ? '' : theme.palette.colors.backgroundgrey,
  borderRadius: '8px',
  '& .MuiSelect-select': {
    padding: 0,

  }

}));
export const InputContainer = styledCmp.div.attrs({
})`
  display: flex;
  flex-direction: column;
  ${props => props.fitContent ? ` 
    width: fit-content;
  ` : `
    width: 100%;
    `
  }
 ${props => props.marginRight ? ` 
    margin-right: 16px;
  ` : ``
  }
  ${props => props.collumn ? `
    flex-direction: column;
  ` : ``}


`;

export const MaterialLabel = styled(InputLabel)(({ theme, ...props }) => ({

}));



export const SelectItem = styled(MenuItem)(({ theme, ...props }) => ({
}));

export const StyledInputLabel = styled(InputLabel)(({ theme, ...props }) => ({
}));