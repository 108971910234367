import React, { useContext, useEffect, useState } from "react";

import {
    FormSpacer,
    HomeContent,
    ProfileContentBackground,
    ProfileContent,
    ProfileFormContent,
    Text
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import useWindowSize from "utils/useWindowSize";
import { CoreContext } from "context/CoreContext";
import { ButtonContainer, Icon, Title } from "ui/styled";
import Input from "components/Form/Input";
import PhoneNumberInput from "components/Phone";
import Button from "components/Form/Button";
import { DeleteUser, UpdateUser } from "services/mobdict";
import useI18n from "hooks/useI18n";
import { toast } from "react-toastify";
import { optionsLevel } from "utils/options";
import { exposeMobdictError } from "utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Check from "components/Form/Check";
import { ReadObject } from "services/storage";

export default function Profile() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { width } = useWindowSize()
    const { setOpened, user, fetchUser } = useContext(CoreContext)
    const [loading, setLoading] = useState(false)
    const [deleteChecked, setDeleteChecked] = useState(false)

    const {t} = useI18n(false)

    const [form, setForm] = useState({ ...user })
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    useEffect(() => {
        if (width <= 991) {
            setOpened(false)
        } else {
            setOpened(true)
        }
    }, [width])

    const valid = (verbose = false) => {

        if(formValue('password')?.length){
            if (formValue('password') !== formValue('cpassword')) {
                if (verbose) { toast.error( t("dashboard_form_password_validation") ); }
                return false;
            }
        }else{
            if (
                (!formValue('nome') || !formValue('nome').length) ||
                (!formValue('username') || !formValue('username').length) ||
                
                (!formValue('email') || !formValue('email').length) ||
                (!formValue('tel') || !formValue('tel').length)
                
            ) {
                if (verbose) { toast.error( t("lg-error-pc") ); }
                return false;
            }
        }


        return true
    }

    const save = async () => {
        if(!valid(true) || loading){ return ;}
        setLoading(true)

        if (deleteChecked) {
            const result = await DeleteUser(user?.id)
            if( !exposeMobdictError(result, !( typeof result?.data === 'string') )){
              await fetchUser(user?.id)
              toast.success(result?.message)
              navigate('dashboard')
              return
            }

        } else {
            const payload = formValue('password')?.length ? {
                senha: form?.password,

                nome: user?.nome,
                username: user?.username,
                email: user?.email,
                tel: user?.tel,
                tel_pais: "Brazil (Brasil)",

                idioma_nativo: user?.id_idioma_nativo,
                idioma_default: user?.id_idioma,
                level: optionsLevel?.find(f => f.title === user?.level)?.id,
                subgeneros: user?.subgen_fav,
                mfa: user?.mfa
            } : {
                nome: form?.nome,
                username: form?.username,
                email: form?.email,
                tel: form?.tel,
                tel_pais: "Brazil (Brasil)",

                idioma_nativo: user?.id_idioma_nativo,
                idioma_default: user?.id_idioma,
                level: optionsLevel?.find(f => f.title === user?.level)?.id,
                subgeneros: user?.subgen_fav,
                mfa: user?.mfa
            }

            const result = await UpdateUser(payload, user?.id )
            if( !exposeMobdictError(result, !( typeof result?.data === 'string') )){
                await fetchUser(user?.id)
                toast.success(result?.message)
                navigate('dashboard')
            }
        }

        setLoading(false)
    }

    return (
        <>
            <ContainerAuthenticated>
                <HomeContent>
                    <>
                        <ProfileContentBackground>
                            <ProfileContent>
                                <Title hasIcon>
                                    <Icon icon='logo-mobdict' nomargin width={44} />
                                    { t("dashboard_form_account_title") }
                                </Title>
                                <FormSpacer />
                                <ProfileFormContent>
                                    <Input placeholder={ t("dashboard_form_name") } id={'name'} value={formValue('nome')} onChange={e => changeForm(e.target.value, 'nome')} />
                                    <Input placeholder={ t("dashboard_form_nickname") } id={'username'} value={formValue('username')} onChange={e => changeForm(e.target.value, 'username')} />
                                </ProfileFormContent>
                                <FormSpacer />
                                <ProfileFormContent>
                                    <Input placeholder={ t("dashboard_form_email") } id={'email'} value={formValue('email')} onChange={e => changeForm(e.target.value, 'email')} />
                                    <PhoneNumberInput value={formValue('tel')} onChange={e => changeForm(e, 'tel')} />
                                </ProfileFormContent>
                                <FormSpacer border />
                                {/* <Title>
                                    { t("dashboard_form_password_title") }
                                </Title>
                                <FormSpacer marginTop={'16px'} />
                                <ProfileFormContent>
                                    <Input placeholder={ t("dashboard_form_password") } id={'password'} type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} />
                                    <Input placeholder={ t("dashboard_form_confirm_password") } id={'cpassword'} type="password" value={formValue('cpassword')} onChange={e => changeForm(e.target.value, 'cpassword')} />
                                </ProfileFormContent>
                                <FormSpacer border /> */}
                                <Check checked={deleteChecked} label={t("dashboard_form_delete_checkbox")} onChange={setDeleteChecked} />
                                {deleteChecked && <Text secondary>{ t("dashboard_form_delete_msg") }</Text>}
                                <ButtonContainer end space >
                                    <Button secondary width="144px" loading={loading} nospace onClick={save}
                                    >{ t("dashboard_form_next") }</Button>
                                </ButtonContainer>

                            </ProfileContent>
                        </ProfileContentBackground>
                    </>
                </HomeContent>
            </ContainerAuthenticated>
        </>
    );
}