import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { theme } from 'ui/theme-color';
import { PhoneContainer, PhoneInputLabel } from './styled';
import { InputLabel } from '@mui/material';
import useI18n from 'hooks/useI18n';
import { timeZoneCityToCountry } from 'utils/timezones';
import { GetPhoneCode } from 'services/mobdict'; 
import { exposeMobdictError } from 'utils'

const PhoneNumberInput = ({ id, secondary, value, onChange}) => {

  const { t } = useI18n(false)
  
  const handleCode = async () => {
    var userCity;
    var userCountry;
    var userTimeZone;
  
    if (Intl) {
      userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      var tzArr = userTimeZone.split("/");
      userCity = tzArr[tzArr.length - 1];
      userCountry = timeZoneCityToCountry[userCity];
    }
    
    const result = await GetPhoneCode({"pais": userCountry});
    
    if (!exposeMobdictError(result, result?.data?.codigo)) {
      return result?.data?.codigo
    }
  }
  
  const [country, setCountry] = useState('');
  const [phoneValue, setPhoneValue] = useState(value || '');
  
  useEffect(() => {
    const fetchCountryCode = async () => {
      const code = await handleCode();
      setCountry(code);
    };
  
    if (!value) {fetchCountryCode();}
  }, []);

  useEffect(() => {
    setPhoneValue(value || '');
  }, [value]);

  const handleChange = (val) => {
    setPhoneValue(val);
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <PhoneContainer>
      <InputLabel htmlFor={id}
        color={secondary ? 'secondary' : 'primary'} >{ t("dashboard_form_phone") }</InputLabel>
      <PhoneInput
        country={country}
        value={phoneValue}
        onChange={handleChange}
        enableSearch
        autoFormat
        inputStyle={{
          width: '100%',
          height: '48px',
          fontSize: '16px',
          borderRadius: '8px',
          border: 'none',
          background: theme.palette.colors.backgroundgrey,
          boxShadow: 'none',
        }}
        buttonStyle={

          {
            border: 'none',
            background: theme.palette.colors.backgroundgrey,
            borderRadius: '8px',
          }
        }
      />
    </PhoneContainer>
  );
};

export default PhoneNumberInput;

