import React, { useContext, useState, } from "react";

import DashboardSide from "../Side";
import {
    DashboardHeaderContainer,
    DashboardHeaderAction,
    DashboardHeaderActionIcon,
    HeaderContent,
} from "./styled";

import SectionHeader from "components/SectionHeader";
import { CoreContext } from "context/CoreContext";
import InfoStudying from "components/InfoStudying";
import useWindowSize from "utils/useWindowSize";
import { Icon } from "ui/styled";

export default function DashboardHeader({ close, saying, checkout }) {

    const { opened, setOpened } = useContext(CoreContext)
    // const { width } = useWindowSize()

    return (
        <>
            <HeaderContent>
                <DashboardSide opened={opened} setOpened={setOpened} />
                <DashboardHeaderContainer saying={saying} checkout={checkout}>
                    <DashboardHeaderAction >
                        {saying || checkout ? null : <DashboardHeaderActionIcon src={`/icons/menu.svg`} alt="menu-icon" onClick={() => setOpened(true)} />}
                        {!checkout ? null : <Icon icon={'logo'} />}
                        {saying ? <InfoStudying /> : null}
                    </DashboardHeaderAction>
                    {checkout ? null : <SectionHeader close={close} />}
                    {/*  */}
                </DashboardHeaderContainer>
            </HeaderContent>
        </>
    );
}